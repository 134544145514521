import { graphql, useStaticQuery } from "gatsby"

const useDocuments = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        documents(first: 100) {
          nodes {
            documentInfo {
              document {
                mediaItemUrl
                sourceUrl(size: THUMBNAIL)
              }
              documenttype
            }
            title
          }
        }
      }
    }
  `)

  return data.wordPress.documents.nodes
}

export default useDocuments