import React from "react"
import Sidebar from "../../components/aboutPage/sidebar"
import useDocuments from "../../hooks/use-documents"
import styled from "@emotion/styled"
import { Helmet } from "react-helmet"

const ContentWrapper = styled("div")``

const ListWrapper = styled.div`
  margin: 0 0 3rem 0;
`

const ItemWrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-bottom: 1px solid gray;
  img {
    width: 40px;
    margin-right: 1rem;
  }
`

const Accounting = props => {
  const data = useDocuments()

  const regulations = data.filter(document => {
    return document.documentInfo.documenttype === "otchetnie"
  })

  const regulationsList = regulations.map(document => (
    <ItemWrapper key={document.title}>
      <img src={document.documentInfo.document.sourceUrl} alt="" />
      <a
        dangerouslySetInnerHTML={{ __html: document.title }}
        href={document.documentInfo.document.mediaItemUrl}
        target="_blank"
        rel="noreferrer"
        aria-label={document.title}
      />
    </ItemWrapper>
  ))

  return (
    <Sidebar>
      <Helmet>
        <title>Отчетные документы</title>
        <meta
          name="description"
          content="Отчетные документы Фонд защиты прав граждан -  участников строительства ПК"
        />
      </Helmet>
      <ContentWrapper>
        <div>
          <h1>Отчетные документы</h1>
          <ListWrapper>{regulationsList}</ListWrapper>
        </div>
      </ContentWrapper>
    </Sidebar>
  )
}

export default Accounting
